import { useCallback, useEffect, useRef } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { StoreEntry } from "./components/StoreEntry";
import { Pagination } from "./components/Pagination";
import { filterPaginate } from "./filterPaginate";
import type { Store, StoreRecord } from "./types";

export function ShowStore({
  store,
  remove,
}: {
  store: Store;
  set: (key: string, value: string) => void;
  remove: (key: string) => void;
}) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get("search");
  const currentPage = parseInt(searchParams.get("page") ?? "1");

  const containerRef = useRef<HTMLDivElement>(null);

  const handleChangePage = useCallback(
    (newPage: number) => {
      // Preserve search term. This is the only way I found, maybe there's an alternative way to just change one part of the query string?
      const newQuery: { search?: string; page?: string } = {};
      if (searchTerm) newQuery.search = searchTerm;
      if (newPage > 1) newQuery.page = newPage.toString();
      setSearchParams(newQuery);
    },
    [setSearchParams, searchTerm]
  );

  useEffect(() => {
    // This is fragile, but the scrollable div contains the <Outlet /> and it's complicated to pass or change.
    (containerRef.current?.parentNode as HTMLDivElement)?.scrollTo({
      top: 0,
    });
  }, [currentPage, searchTerm]);

  const handleEdit = useCallback(
    ({ url, description, title, tags }: StoreRecord) => {
      navigate(
        `/add?url=${encodeURIComponent(url)}&description=${encodeURIComponent(
          description
        )}&title=${encodeURIComponent(title)}${
          tags.length ? tags.map((tag) => `&tags=${tag}`) : ""
        }`
      );
    },
    [navigate]
  );

  const { shownEntries, nPages } = filterPaginate(
    store,
    searchTerm,
    currentPage
  );

  return (
    <>
      <div ref={containerRef} className="pt-7 pb-3.5 flex flex-col">
        {shownEntries.map(({ key, ts, t, d, g }) => (
          <StoreEntry
            key={key}
            url={key}
            title={t}
            description={d}
            tags={g}
            ts={ts}
            onDelete={() => remove(key)}
            onEdit={handleEdit}
          />
        ))}
        {!!shownEntries.length && (
          <Pagination
            currentPage={currentPage}
            totalPages={nPages}
            onChangePage={handleChangePage}
          />
        )}
      </div>
      <NavLink
        to="/add"
        title="Add URL"
        className="fixed z-90 bottom-10 right-8 bg-indigo-600 w-12 h-12 rounded-full drop-shadow-lg flex justify-center items-center text-white border text-2xl hover:bg-indigo-700"
      >
        +
      </NavLink>
    </>
  );
}
