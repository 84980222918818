import type {
  NetworkConnector,
  NetworkConnectorOptions,
} from "./BroadcastProtocolSyncProvider";

interface WSConnectorOptions {
  url: string;
  authToken: string;
}

// This, as an handler, knows nothing of the shape of the messages it passes, but everything of how to pass them
export class WSConnector implements NetworkConnector {
  static initialTimeoutMs = 5000;
  static maxTimeoutMs = 60000;
  private onMessage?: (message: unknown) => void;
  private wsServerUrl: string;
  private ws?: WebSocket;
  private documentId?: string;
  public connected = false;
  private stopped = false;
  private reconnectTimeout?: ReturnType<typeof setTimeout>;
  private nextTimeoutMs: number = WSConnector.initialTimeoutMs;
  private authToken: string;

  constructor({ url, authToken }: WSConnectorOptions) {
    this.wsServerUrl = url;
    this.authToken = authToken;
  }

  init({ onMessage, documentId }: NetworkConnectorOptions) {
    this.onMessage = onMessage;
    this.documentId = documentId;
  }

  start = () => {
    if (!this.onMessage || !this.documentId) {
      throw new Error("WSConnector.start() called before WSConnector.init()");
    }
    this.stopped = false;
    this.ws = new WebSocket(`${this.wsServerUrl}?roomId=${this.documentId}`, [
      "Authentication",
      this.authToken,
    ]);
    this.ws.onopen = this.onWSOpen;
    this.ws.onclose = this.onWSClose;
    this.ws.onerror = this.onWSError;
    this.ws.onmessage = this.receiveMessage;
  };

  stop = () => {
    this.stopped = true;
    this.clearExponentialTimeout();
    this.ws?.close();
  };

  broadcast = async (message: unknown) => {
    if (!this.ws || !this.connected) {
      // we will broadcast later
      return;
    }
    this.ws.send(JSON.stringify(message));
  };

  private onWSClose = () => {
    this.connected = false;
    if (!this.stopped) {
      this.setExponentialTimeout();
    }
  };

  private onWSError = (error: Event) => {
    console.error(
      "WSConnector Socket encountered error: ",
      error,
      "Closing socket"
    );
    this.ws?.close();
  };

  private onWSOpen = () => {
    this.connected = true;
  };

  private receiveMessage = (message: { data: string }) => {
    try {
      const data = JSON.parse(message.data);
      this.onMessage && this.onMessage(data);
    } catch (e) {
      console.error("WSConnector - Error parsing message", e);
    }
  };

  private setExponentialTimeout = () => {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
    this.reconnectTimeout = setTimeout(this.start, this.nextTimeoutMs);
    console.log("Reconnecting in ", this.nextTimeoutMs, "ms");

    this.nextTimeoutMs = Math.min(
      this.nextTimeoutMs * 1.5,
      WSConnector.maxTimeoutMs
    );
  };

  private clearExponentialTimeout = () => {
    this.nextTimeoutMs = WSConnector.initialTimeoutMs;
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = undefined;
    }
  };
}
