export function Pagination({
  currentPage,
  totalPages,
  onChangePage,
}: {
  currentPage: number;
  totalPages: number;
  onChangePage: (p: number) => void;
}) {
  return (
    <div className="inline-flex items-center justify-center space-x-3 pt-3.5">
      {currentPage > 1 && (
        <button
          className="inline-flex items-center justify-center w-8 h-8 border border-gray-100 rounded"
          onClick={() => onChangePage(currentPage - 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-3 h-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      )}

      <p className="text-xs">
        {currentPage}
        <span className="mx-0.25">/</span>
        {totalPages}
      </p>

      {currentPage < totalPages && (
        <button
          className="inline-flex items-center justify-center w-8 h-8 border border-gray-100 rounded"
          onClick={() => onChangePage(currentPage + 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-3 h-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      )}
    </div>
  );
}
