import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { useDocument } from "./useDocument";
import { useAuth } from "./useAuth";
import { ShowStore } from "./ShowStore";
import { Layout } from "./Layout";
import { Home } from "./Home";
import { Add } from "./Add";

function App() {
  const {
    awaitingMailPrompt,
    userSession,
    error,
    isAuthenticating,
    signIn,
    signOut,
  } = useAuth();
  const { store, set, remove } = useDocument(
    userSession?.user?.id,
    userSession?.access_token
  );

  if (error) {
    return <p>An auth error has occurred! {error.message}</p>;
  }

  if (isAuthenticating) {
    return <p>Authenticating...</p>;
  }

  if (awaitingMailPrompt) {
    return <p>Check your email to get a sign-in link.</p>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Layout user={userSession?.user} signOut={signOut} />}
        >
          <Route
            index
            element={<Home user={userSession?.user} signIn={signIn} />}
          />
          <Route
            path="/add"
            element={
              <Authenticated hasUser={!!userSession?.user}>
                <Add set={set} />
              </Authenticated>
            }
          ></Route>
          <Route
            path="/store"
            element={
              <Authenticated hasUser={!!userSession?.user}>
                <ShowStore store={store} set={set} remove={remove} />
              </Authenticated>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Authenticated({
  hasUser,
  children,
}: {
  hasUser: boolean;
  children: JSX.Element;
}) {
  if (!hasUser) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
}

export default App;
