import type { Store } from "./types";

const PAGE_SIZE = 10;
const searchTagRegexp = /tag:\s*(\w+)/g;

export function filterPaginate(
  store: Store,
  searchTerm: string | null,
  currentPage: number,
  pageSize = PAGE_SIZE
) {
  console.time("Filtering entries");

  // TODO: somehow debounce or memoize this! Can't debounce on the other side because we're directly setting the url's search query :(
  let filteredEntries = Object.entries(store)
    .map(([key, { ts, value }]) => {
      const { t, d, g } = JSON.parse(value || "{}") as {
        t: string;
        d: string;
        g: string[];
      };
      return {
        key: key.toLowerCase(),
        ts,
        t: t.toLowerCase(),
        d: d.toLowerCase(),
        g: g.map((tag) => tag.toLowerCase()),
      };
    })
    .reverse();

  if (searchTerm) {
    const lowerSearchTerm = searchTerm.toLowerCase();

    const tokens = lowerSearchTerm
      .replace(searchTagRegexp, "")
      .replace(/\s{2,}/g, " ")
      .split(" ")
      .filter((t) => t.length >= 3);

    const searchTags = Array.from(searchTerm.matchAll(searchTagRegexp)).map(
      ([_, group]) => group
    );

    filteredEntries = filteredEntries.filter(
      ({ key, t, d, g }) =>
        searchTags.every((searchTag) => g.includes(searchTag)) &&
        (!tokens.length ||
          tokens.every(
            (token) =>
              t.includes(token) ||
              key.includes(token) ||
              key.includes(token) ||
              d.includes(token) ||
              g.some((tag) => tag.includes(token))
          ))
    );
  }

  const nPages = Math.max(Math.floor(filteredEntries.length / pageSize), 1);

  const shownEntries = filteredEntries.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  console.timeEnd("Filtering entries");
  return { shownEntries, nPages };
}
