import { nanoid } from "nanoid";
import { uniqBy } from "lodash";
export interface StampedMessage {
  hlc: string;
  data: unknown;
}
export interface HLC {
  ts: number;
  count: number;
  node: string;
}
export interface SigilInterface {
  update: (messages: StampedMessage[], providerName: string) => void;
  set: (message: unknown) => void;
  unpack: (hlc: string) => HLC;
}

export interface ProviderInterface {
  name: string;
  init?(
    methods: SigilInterface,
    nodeId: string,
    documentId: string
  ): Promise<void>;
  restore?(): Promise<StampedMessage[]>;
  update?(messages: StampedMessage[]): void;
  start?(messages: StampedMessage[]): void;
  destroy?: () => void;
}

// These are keys of syncStore
export const nodeIdKey = "nodeId";
export class Sigil {
  private nodeId?: string;
  private hlc?: HLC;
  private providers: ProviderInterface[] = [];

  private static messageCmp(a: StampedMessage, b: StampedMessage) {
    return cmp(unpack(a.hlc), unpack(b.hlc)); // TODO probably we can avoid unpack-ing and do a lexicographical comparison on the packed ones
  }

  public static create = async (
    documentId: string,
    providers: ProviderInterface[]
  ) => {
    const instance = new Sigil();
    // See if the node id has already been saved; if not, generate it and save it
    let nodeId = localStorage.getItem(nodeIdKey);
    if (!nodeId) {
      nodeId = nanoid();
      localStorage.setItem(nodeIdKey, nodeId);
    }
    instance.nodeId = nodeId;
    // Now that we have the node id, we can initialise the HLC
    instance.hlc = init(nodeId, Math.round(Date.now() / 1000));

    instance.providers = providers;

    const providerNodeId = nodeId; // This is only to type nodeId better
    // Initialise the providers: first we wait for them to init()
    // Then we wait for them to restore() and collect the restored messages
    // Then we send an onStart event to all of them with the restored messages, sorted and deduplicated
    await Promise.all(
      providers
        .filter((provider) => !!provider.init)
        .map((provider) => {
          const sigil: SigilInterface = {
            set: instance.set,
            update: instance.update,
            unpack,
          };
          return provider.init!(sigil, providerNodeId, documentId); // TODO type
        })
    );

    // Get all the restored records before starting the providers
    const restoredMessages = await Promise.all(
      providers.map((provider) => (provider.restore ? provider.restore() : []))
    );
    // flatten, sort and deduplicate
    const restoredMessagesOrdered = uniqBy(
      restoredMessages.flat().sort(Sigil.messageCmp),
      ({ hlc }) => hlc
    );

    // Finally send an onStart event to everyone who is listening
    instance.providers.forEach(
      ({ start: onStart }) => onStart && onStart(restoredMessagesOrdered)
    );

    return instance;
  };

  public destroy = () => {
    this.providers.forEach(
      ({ destroy: onDestroy }) => onDestroy && onDestroy()
    );
  };

  // When the message comes from "network" providers, they call update
  // Providers mustn't update on update
  private update = (messages: StampedMessage[], providerName: string) => {
    const otherProviders = this.providers.filter(
      ({ name, update: onUpdate }) => !!onUpdate && name !== providerName
    );

    const messagesToSend = messages
      .filter(
        (stampedMessage) => unpack(stampedMessage.hlc).node !== this.nodeId
      )
      .reduce<StampedMessage[]>((acc, stampedMessage) => {
        const messageHlc = unpack(stampedMessage.hlc);
        if (messageHlc.node === this.nodeId) {
          throw new Error(
            "Received a message from same node. This should not happen"
          );
        }
        // Side effect; update the HLC
        this.hlc = recv(this.hlc!, messageHlc, Math.round(Date.now() / 1000));
        acc.push(stampedMessage);
        return acc;
      }, []);

    // Send the update message to everyone who's listening, except the source provider.
    // This is message from the provider -> other providers.
    otherProviders.forEach(
      ({ update: onUpdate }) => onUpdate && onUpdate(messagesToSend)
    );
  };

  // When the message comes from the user, we must call set.
  private set = (data: unknown) => {
    this.hlc = inc(this.hlc!, Math.round(Date.now() / 1000));
    const hlc = pack(this.hlc);
    const stampedMessage: StampedMessage = { data, hlc };
    // Send the update message to everyone who's listening. This is message from the external -> providers.
    // What happens in case of error from one of the providers?
    this.providers.forEach(
      ({ update: onUpdate }) => onUpdate && onUpdate([stampedMessage])
    );
  };
}

/**
 * This implementation of the [Hybrid Logical Clocks][1] paper was very much based
 * on [this go implementation][2] and [james long's demo][3]
 *
 * [1]: https://muratbuffalo.blogspot.com/2014/07/hybrid-logical-clocks.html
 * [2]: https://github.com/lafikl/hlc/blob/master/hlc.go
 * [3]: https://github.com/jlongster/crdt-example-app/blob/master/shared/timestamp.js
 */

function pack({ ts, count, node }: HLC) {
  // 11 second resolution, 8 digits base 36. Should be ok until 2863
  // 5 digits base 36 is enough for more than 6 million changes.
  return (
    ts.toString(36).padStart(8, "0") +
    ":" +
    count.toString(36).padStart(5, "0") +
    ":" +
    node
  );
}

export function unpack(serialized: string) {
  const [ts, count, ...node] = serialized.split(":");
  return {
    ts: parseInt(ts, 36),
    count: parseInt(count, 36),
    node: node.join(":"),
  };
}

function init(node: string, now: number): HLC {
  return {
    ts: now,
    count: 0,
    node,
  };
}

function cmp(one: HLC, two: HLC) {
  if (one.ts === two.ts) {
    if (one.count === two.count) {
      if (one.node === two.node) {
        return 0;
      }
      return one.node < two.node ? -1 : 1;
    }
    return one.count - two.count;
  }
  return one.ts - two.ts;
}

function inc(local: HLC, now: number): HLC {
  if (now > local.ts) {
    return { ts: now, count: 0, node: local.node };
  }

  return { ...local, count: local.count + 1 };
}

function recv(local: HLC, remote: HLC, now: number): HLC {
  if (now > local.ts && now > remote.ts) {
    return { ...local, ts: now, count: 0 };
  }

  if (local.ts === remote.ts) {
    return { ...local, count: Math.max(local.count, remote.count) + 1 };
  } else if (local.ts > remote.ts) {
    return { ...local, count: local.count + 1 };
  } else {
    return { ...local, ts: remote.ts, count: remote.count + 1 };
  }
}

// const maxPackableCount = parseInt("zzzzz", 36);

// function validate(time: HLC, now: number, maxDrift: number = 60 * 1000) {
//   if (time.count > maxPackableCount) {
//     return "counter-overflow";
//   }
//   // if a timestamp is more than 1 minute off from our local wall clock, something has gone horribly wrong.
//   if (Math.abs(time.ts - now) > maxDrift) {
//     return "clock-off";
//   }
//   return null;
// }
