import React, { useCallback } from "react";
import { useSearchParams, Link } from "react-router-dom";

export function Tag({ title }: { title: string }) {
  const setSearchParams = useSearchParams()[1];

  const handleTagSelection = useCallback(
    (title: string, e: React.MouseEvent) => {
      e.preventDefault();
      setSearchParams(`search=tag: ${title}`);
    },
    [setSearchParams]
  );
  return (
    <Link
      onClick={(e) => handleTagSelection(title, e)}
      to={`/?search=tag:${title}`}
      className="inline-block pr-1 py-1 mr-2 text-sm font-normal text-indigo-600 bg-white"
    >
      {title}
    </Link>
  );
}
