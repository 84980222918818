import { ChangeEvent, useCallback } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import type { User } from "@supabase/supabase-js";

export function Navbar({
  user,
  signOut,
  className,
}: {
  user: User | null | undefined;
  signOut: () => Promise<void>;
  className: string;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSignOut = useCallback(async () => {
    await signOut();
  }, [signOut]);

  const handleSearchInput = useCallback(
    (e: ChangeEvent) => {
      const { value } = e.target as HTMLInputElement;
      if (!value) setSearchParams("");
      else setSearchParams(`search=${value}`);
    },
    [setSearchParams]
  );

  return (
    <header className={`${className} shadow-sm`}>
      <div className="flex items-center justify-between h-16 px-4 xl:px-24">
        <div className="flex items-center space-x-4">
          <NavLink
            to="/"
            title="Home"
            className="p-2 text-gray-500 bg-gray-100 rounded-full"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
              />
            </svg>
          </NavLink>

          {user && (
            <div className="lg:flex">
              <div className="relative">
                <input
                  className="h-10 text-sm placeholder-gray-300 border-gray-200 rounded-lg focus:z-10"
                  placeholder="Search..."
                  type="text"
                  value={searchParams.get("search") ?? ""}
                  onChange={handleSearchInput}
                />

                <button
                  className="absolute inset-y-0 right-0 p-2 mr-px text-gray-600 rounded-r-lg"
                  type="submit"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-end flex-1 w-0 sm:hidden">
          {user ? (
            <button
              className="p-2 text-gray-500 bg-gray-100 rounded-full"
              type="button"
              onClick={signOut}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          ) : (
            <button
              className="p-2 text-gray-500 bg-gray-100 rounded-full"
              type="button"
              onClick={signOut}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </div>

        <div className="items-center hidden space-x-4 sm:flex">
          {user ? (
            <button
              className="px-5 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-lg"
              onClick={handleSignOut}
            >
              Sign out
            </button>
          ) : (
            <button className="px-5 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-lg">
              Sign in
            </button>
          )}
        </div>
      </div>
    </header>
  );
}
