import { unpack } from "./Sigil";
import type {
  SigilInterface,
  ProviderInterface,
  StampedMessage,
} from "./Sigil";
import type { Store } from "../types";

export type HLCStore = Record<string, string>;
interface LWWMessage extends StampedMessage {
  data: {
    key: string;
    value: string;
    deleted: boolean;
  };
}

export class LWWMapProvider implements ProviderInterface {
  public name = "lww-map-provider";
  private sigil?: SigilInterface;
  private onStoreUpdate?: (b: Store) => void;
  private backingStore: Store = {};
  private hlcStore: HLCStore = {};

  constructor(onStoreUpdate?: (b: Store) => void) {
    this.onStoreUpdate = onStoreUpdate;
  }

  async init(sigil: SigilInterface) {
    this.sigil = sigil;
  }

  start = (messages: StampedMessage[]) => {
    this.setState(messages);
  };

  update = (messages: StampedMessage[]) => {
    this.setState(messages);
  };

  private setState(messages: StampedMessage[]) {
    console.time(`Calculating state from ${messages.length} new messages`);
    let changed = false;
    messages.forEach((message) => {
      const { data } = message as LWWMessage;
      const { key, value, deleted } = data;
      if (!(key in this.backingStore) || this.hlcStore[key] < message.hlc) {
        changed = true;
        if (deleted) {
          delete this.backingStore[key];
        } else {
          this.backingStore[key] = {
            ts: unpack(message.hlc).ts,
            value,
          };
        }
        this.hlcStore[key] = message.hlc;
      }
    });
    console.timeEnd(`Calculating state from ${messages.length} new messages`);
    if (changed && this?.onStoreUpdate) {
      this.onStoreUpdate({ ...this.backingStore });
    }
  }

  public set = (key: string, value: string) => {
    if (!this.sigil) {
      throw new Error(this.name + " - Sigil not initialised");
    }
    this.sigil.set({ key, value, deleted: false });
  };

  public remove = (key: string) => {
    if (!this.sigil) {
      throw new Error(this.name + " - Sigil not initialised");
    }
    this.sigil.set({ key, deleted: true });
  };
}
