import { useCallback, useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import type { ApiError, Session } from "@supabase/supabase-js";

// TODO see if it's possible to jus use gotrue's auth library
const supabase = createClient(
  "https://wdkyfewhogjjquvucefz.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indka3lmZXdob2dqanF1dnVjZWZ6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTE1OTU4OTEsImV4cCI6MTk2NzE3MTg5MX0.3k4p3XjpUxMDHJgVcJ58_xS4lNVakd-_p3UJV9NyOZw"
);

export function useAuth() {
  const [userSession, setUserSession] = useState<Session | null>(
    supabase.auth.session()
  );
  const [error, setError] = useState<ApiError | null>(null);
  const [awaitingMailPrompt, setAwaitingMailPrompt] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const signIn = useCallback(async (email: string) => {
    setIsAuthenticating(true);
    const { user, error } = await supabase.auth.signIn(
      {
        email,
      },
      {
        shouldCreateUser: false,
        redirectTo: document.location.origin,
      }
    );
    setIsAuthenticating(false);
    setUserSession(supabase.auth.session());
    setError(error);
    if (!user && !error) {
      setAwaitingMailPrompt(true);
    }
  }, []);

  const signOut = useCallback(async () => {
    const { error } = await supabase.auth.signOut();
    setError(error);
    if (!error) {
      setUserSession(null);
    }
  }, []);

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN" && session) {
        setUserSession(session);
      }
      if (event === "SIGNED_OUT" && session) {
        setUserSession(null);
      }
    });
  });

  return {
    awaitingMailPrompt,
    isAuthenticating,
    userSession,
    error,
    signIn,
    signOut,
  };
}
