import type { ProviderInterface, StampedMessage } from "./Sigil";

export class IndexedDBProvider implements ProviderInterface {
  name = "indexed-db-provider";
  private db?: IDBDatabase;
  private documentId?: string;
  static storeName: string = `record-store`;

  public async init(
    _: unknown,
    providerNodeId: string,
    documentId: string
  ): Promise<void> {
    this.documentId = documentId;
    const dbName = `sigil-${this.name}-${providerNodeId}-${documentId}-db`;
    return new Promise((resolve) => {
      const openDBRequest = indexedDB.open(dbName, 1);

      openDBRequest.onerror = (event) => {
        console.error(event);
        throw new Error(`Can't open IndexedDB`);
      };

      openDBRequest.onsuccess = () => {
        this.db = openDBRequest.result;
        resolve();
      };

      openDBRequest.onupgradeneeded = () => {
        const store = openDBRequest.result.createObjectStore(
          IndexedDBProvider.storeName,
          {
            keyPath: "hlc",
          }
        );
        store.transaction.oncomplete = () => {
          console.log("store created");
        };
      };
    });
  }

  restore = async () => {
    if (!this.db) {
      throw new Error("IndexedDBProvider not initialised");
    }
    const { db } = this;
    return new Promise<StampedMessage[]>((resolve) => {
      const objectStore = db
        .transaction(IndexedDBProvider.storeName)
        .objectStore(IndexedDBProvider.storeName);
      objectStore.getAll().onsuccess = (event) => {
        const data = (event.target as IDBRequest)
          ?.result as unknown as StampedMessage[];
        resolve(data);
      };
    });
  };

  // http://localhost:3000/add?url=https%3A%2F%2Fdev.to%2Fsmpnjn%2Ffuture-javascript-shadowrealms-20mg%3Fck_subscriber_id%3D1106769505&description=&title=Future%20Javascript%3A%20ShadowRealms%20-%20DEV%20Community
  update = async (messages: StampedMessage[]) => {
    if (!this.db || !this.documentId) {
      throw new Error("IndexedDBProvider not initialised");
    }
    const transaction = this.db.transaction(
      [IndexedDBProvider.storeName],
      "readwrite"
    );
    const objectStore = transaction.objectStore(IndexedDBProvider.storeName);
    return Promise.all(
      messages.map(
        (message) =>
          new Promise((resolve) => {
            const request = objectStore.add(message);
            request.onerror = (event) => {
              console.error(event);
              throw new Error(`Can't save to IndexedDB`);
            };
            request.onsuccess = () => {
              resolve(undefined);
            };
          })
      )
    );
  };

  destroy = () => this.db?.close();
}
