import { useState } from "react";
import { formatDistance } from "date-fns";
import { Tag } from "./Tag";
import type { StoreRecord } from "../types";

interface StoreProps extends StoreRecord {
  onEdit: (r: StoreRecord) => void;
  onDelete: () => void;
}

export function StoreEntry({
  url,
  title,
  description,
  tags,
  ts,
  onDelete,
  onEdit,
}: StoreProps) {
  const nowSecs = Math.round(Date.now() / 1000);
  const normalizedTs = Math.min(nowSecs, ts);
  const timestamp = formatDistance(new Date(normalizedTs * 1000), new Date(), {
    addSuffix: true,
  });
  const parsedUrl = new URL(url);
  const firstLevelHostname = parsedUrl.hostname.split(".").slice(-2).join(".");
  const webArchiveUrl = `https://web.archive.org/web/*/${parsedUrl.href.replace(
    /\/$/,
    ""
  )}`;
  const [isDeleting, setDeleting] = useState(false);
  return (
    <div className="flex items-start pt-2 first:pt-0 pb-4 border-b border-slate-100">
      <div className="mx-4 xl:mx-24 max-w-5xl">
        <span className="flex items-center">
          <strong className="font-medium leading-loose text-base">
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="hover:underline"
            >
              {title || url}
            </a>
          </strong>
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="hover:underline pl-1 text-xs text-slate-400"
          >
            ({firstLevelHostname})
          </a>
          <a
            target="_blank"
            href={webArchiveUrl}
            rel="noreferrer"
            title="Archive with The Wayback Machine"
            className="pl-1 text-xs text-slate-400"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
              />
            </svg>
          </a>
        </span>

        <p className="mt-0.5 text-sm text-slate-600 leading-relaxed line-clamp-3">
          {description || "No description"}
        </p>

        <div className="mt-1">
          <div className="text-xs text-gray-500">
            {tags.map((tag) => (
              <Tag key={tag} title={tag} />
            ))}
            <p className="pt-1">
              {timestamp}
              {" - "}
              <button
                onClick={() => onEdit({ title, description, url, tags, ts })}
                className="inline-block text-xs text-gray-500 mr-1 bg-transparent hover:text-gray-700 hover:underline hover:decoration-gray-300 focus:outline-none "
              >
                edit
              </button>
              {isDeleting ? (
                <>
                  <button
                    onClick={onDelete}
                    className="inline-block text-xs text-gray-500 mr-1 bg-transparent hover:text-gray-700 hover:underline hover:decoration-gray-300 focus:outline-none "
                  >
                    confirm?
                  </button>
                  <button
                    onClick={() => setDeleting(false)}
                    className="inline-block text-xs text-gray-500 mr-1 bg-transparent hover:text-gray-700 hover:underline hover:decoration-gray-300 focus:outline-none "
                  >
                    cancel
                  </button>
                </>
              ) : (
                <button
                  onClick={() => setDeleting(true)}
                  className="inline-block text-xs text-gray-500 mr-1 bg-transparent hover:text-gray-700 hover:underline hover:decoration-gray-300 focus:outline-none "
                >
                  delete
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
