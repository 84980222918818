import { useEffect, useState, useCallback, useRef } from "react";
import {
  BroadcastChannel,
  createLeaderElection,
  LeaderElector,
} from "broadcast-channel";
import { Sigil } from "./sigil/Sigil";
import { WSConnector } from "./sigil/WSConnector";
import { IndexedDBProvider } from "./sigil/IndexedDBProvider";
import { LWWMapProvider } from "./sigil/LWWMapProvider";
import { BroadcastProtocolSyncProvider } from "./sigil/BroadcastProtocolSyncProvider";
import type { Store, StoreUnparsedRecord } from "./types";

type InterTabMessage =
  | {
      type: "set";
      key: string;
      value: string;
    }
  | {
      type: "remove";
      key: string;
    }
  | { type: "ehlo" }
  | { type: "state"; state: Store };

export function useDocument(documentId?: string, authToken?: string) {
  const [sigilInstance, setSigilInstance] = useState<Sigil>();
  // Use mutable state via ref to avoid stale state
  // https://css-tricks.com/dealing-with-stale-props-and-states-in-reacts-functional-components/
  const [, forceRender] = useState({});
  const store = useRef<Store>({});
  const [lwwInstance, setLWWInstance] = useState<LWWMapProvider>();
  const latestId = useRef<string | undefined>(undefined);
  const electorRef = useRef<LeaderElector>();
  const electorChannelRef = useRef<BroadcastChannel>();
  const commsChannelRef = useRef<BroadcastChannel>();

  const setStore = useCallback((value: Record<string, StoreUnparsedRecord>) => {
    store.current = value;
    forceRender({});
  }, []);

  const initialiseSigil = useCallback(
    async (id: string, authToken: string) => {
      const communicationHandler = new WSConnector({
        url: "wss://field-victorious-monkey.glitch.me",
        authToken,
      });
      const broadcastProvider = new BroadcastProtocolSyncProvider(
        communicationHandler
      );
      const indexedDbProvider = new IndexedDBProvider();
      const lwwMapProvider = new LWWMapProvider((newState) => {
        setStore(newState);
        if (electorRef.current?.isLeader && commsChannelRef.current) {
          commsChannelRef.current.postMessage({
            type: "state",
            state: newState,
          });
        }
      });
      setLWWInstance(lwwMapProvider);
      const instance = await Sigil.create(id, [
        broadcastProvider,
        indexedDbProvider,
        lwwMapProvider,
      ]);
      setSigilInstance(instance);
    },
    [setStore]
  );

  const onBroadcastMessage = useCallback(
    (msg: InterTabMessage) => {
      if (electorRef.current?.isLeader) {
        // We are the leader; getting a message from a follower
        if (msg.type === "ehlo") {
          // A follower is saying hello to us. Send the state to it.
          commsChannelRef.current?.postMessage({
            type: "state",
            state: store.current,
          });
        }
      } else {
        // We are a follower; getting a message from the leader
        if (msg.type === "state") {
          setStore(msg.state);
        }
      }
    },
    [setStore]
  );

  const onDestroyDocument = useCallback(() => {
    // This is where we ithe current document gets destroyed
    console.log("Sigil document about to get destroyed");
    // Kill the leader and close the channel
    electorRef.current?.die();
    commsChannelRef.current?.close();
    sigilInstance?.destroy();
    setSigilInstance(undefined);
    setStore({});
    setLWWInstance(undefined);
    latestId.current = undefined;
  }, [sigilInstance, setStore]);

  const onCreateDocument = useCallback(() => {
    // This is where we initialise a new document
    if (!authToken || !documentId) {
      throw new Error("Got create document without authToken or documentId");
    }
    // Start an elector channel and use it to elect a leader
    electorChannelRef.current = new BroadcastChannel(
      `vitr-leader-election-channel-${documentId}`
    );

    // Start a comms channel and use it to communicate from / to the leader
    commsChannelRef.current = new BroadcastChannel(
      `vitr-comms-channel-${documentId}`
    );

    electorRef.current = createLeaderElection(electorChannelRef.current);
    electorRef.current.awaitLeadership().then(() => {
      // Once we are elected leader, we can initialise a Sigil instance for the document
      console.log("👑 This tab is now leader for document 👑", documentId);
      initialiseSigil(documentId, authToken);
    });

    commsChannelRef.current.onmessage = onBroadcastMessage;

    // Send a greeting message. If there's a leader to pick it up, it will respond with state
    commsChannelRef.current?.postMessage({ type: "ehlo" });
  }, [authToken, documentId, initialiseSigil, onBroadcastMessage]);

  useEffect(() => {
    if (latestId.current && !documentId) {
      onDestroyDocument();
    }

    if (!latestId.current && documentId) {
      onCreateDocument();
    }

    latestId.current = documentId;
  }, [documentId, onCreateDocument, onDestroyDocument]);

  const set = useCallback(
    // We need to do two different things based on whether we are the leader or not
    (key: string, value: string) => {
      if (electorRef.current?.isLeader) {
        if (!lwwInstance) {
          throw new Error("Using set before lwwInstance is set");
        }
        lwwInstance.set(key, value);
      } else {
        if (!commsChannelRef.current) {
          throw new Error("Using set, but commsChannel is not set yet");
        }
        commsChannelRef.current.postMessage({
          type: "set",
          key,
          value,
        });
      }
    },
    [lwwInstance]
  );

  const remove = useCallback(
    // We need to do two different things based on whether we are the leader or not
    (key: string) => {
      if (electorRef.current?.isLeader) {
        if (!lwwInstance) {
          throw new Error("Using remove before lwwInstance is set");
        }
        lwwInstance.remove(key);
      } else {
        if (!commsChannelRef.current) {
          throw new Error("Using remove, but commsChannel is not set yet");
        }
        commsChannelRef.current.postMessage({
          type: "remove",
          key,
        });
      }
    },
    [lwwInstance]
  );
  return { store: store.current, set, remove };
}
