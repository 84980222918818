import { useState, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Select, { Option } from "rc-select";

export function Add({ set }: { set: (key: string, value: string) => void }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [url, setUrl] = useState(searchParams.get("url") || "");
  const [title, setTitle] = useState(searchParams.get("title") || "");
  const [tags, setTags] = useState<string[]>(searchParams.getAll("tags") || []);
  const [description, setDescription] = useState(
    searchParams.get("description") || ""
  );

  const handleSubmit = useCallback(
    (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault();
      set(url, JSON.stringify({ t: title, d: description, g: tags }));
      navigate("/store", { replace: true });
    },
    [description, title, url, set, navigate, tags]
  );

  return (
    <section className="h-full bg-gray-100">
      <div className="max-w-screen-md px-4 py-16 mx-auto sm:px-6 lg:px-8 h-full">
        <div className="p-8 bg-white rounded-sm border border-slate-200 shadow-md lg:p-12 lg:col-span-5 h-full">
          <form
            onSubmit={handleSubmit}
            className="space-y-4 h-full flex flex-col place-content-around"
          >
            <div>
              <label className="text-sm font-medium" htmlFor="url">
                URL
              </label>
              <input
                className="w-full p-2 text-sm border border-gray-200 rounded-sm"
                placeholder="https://your-url.com"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                type="url"
                id="url"
              />
            </div>

            <div>
              <label className="text-sm font-medium" htmlFor="title">
                Title
              </label>
              <input
                className="w-full p-2 text-sm border border-gray-200 rounded-sm"
                placeholder="Page title"
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="grow flex flex-col">
              <label className="text-sm font-medium" htmlFor="description">
                Description
              </label>
              <textarea
                className="w-full p-2 text-sm border border-gray-200 rounded-sm grow resize-none"
                placeholder="Page description"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <div>
              <label className="text-sm font-medium" htmlFor="tags">
                Tags
              </label>
              <Select
                id="tags"
                className="w-full border border-gray-200 rounded-sm p-1"
                mode="tags"
                maxTagCount={5}
                maxTagTextLength={14}
                value={tags}
                onChange={(val: string[] /*, option*/) => {
                  setTags(val);
                }}
                tokenSeparators={[" ", ",", "\n"]}
              >
                <Option key={"uncategorized"} test={"uncategorized"}>
                  {"uncategorized"}
                </Option>
              </Select>
            </div>

            <div className="mt-4">
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full px-5 py-2 text-sm text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg sm:w-auto"
              >
                <span className="font-medium"> Add </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 ml-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
