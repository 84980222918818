import { sortedUniqBy, omit, last, map, forEach } from "lodash";
import type {
  SigilInterface,
  ProviderInterface,
  StampedMessage,
} from "./Sigil";

export interface NetworkConnectorOptions {
  documentId: string;
  nodeId: string;
  onMessage: (message: unknown) => void;
  // TODO we can have a .send here, that will immediately send the message to the other peers.
}
export interface NetworkConnector {
  init(options: NetworkConnectorOptions): void;
  broadcast: (message: unknown) => void;
  start(): void;
  stop(): void;
}

interface BroadcastProtocolSyncProviderOptions {
  intervalMs: number;
}

type NodeId = string;
type PackedHLC = string;
type AMessage = [PackedHLC, ...StampedMessage[]];

interface PeerMessage {
  n: NodeId; // this node id
  q?: PackedHLC[]; // the latest HLC per known node, excluding this node. This is a list of HLCs. Some of them can be of the form ::nodeId
  a?: AMessage[]; // an array of [nodeId, ...messages]
}

const MAX_RESPONSE_ITEMS = 5;

/*
* When a message arrives:
  - Check all the a and q fields of the message for a new node.
  - Check the q of the message for every node for an HLC that we know. If we see it, update our state and 
    call update() with the new delta (try to be sure that we don't do this twice, because Sigil doesn't check)
  - Check the a of the message and for every node see if we can help them (if we have their last request in the last position)
  - If we can help a node, set the "next a" state to that HLC for that node
  - If we can't help a node, remove it from the "next a" state (this is important because we don't want to send a useless msg)
  - Every now and then, broadcast a message with:
    - The q for us
    - The a for every node *calculated in that moment* (that is, with the latest state available)
    - Finally, if we helped a node with our a, remove them from the "next a" state
      - Optionally, if we limit the as we send to a node, we can just update their "next a" state if we know there is more
      - Similarly, we can limit the number of the nodes we help by randomly sampling them if they're too many
*
*/

/* {
  n: "nodeId",
  q: [ "timestamp:counter:nodeId", "::nodeId" ],
  a: [ ["nodeId", StampedMessage, StampedMessage, ...], ["nodeId", StampedMessage, StampedMessage, ...] }
 } */

export class BroadcastProtocolSyncProvider implements ProviderInterface {
  public name = "realtime-sync-provider";
  private sigil?: SigilInterface;
  private documentId?: string;
  private nodeId?: string;
  private messagesPerNode: Record<string, StampedMessage[]> = {};
  private networkConnector: NetworkConnector;
  private peerStatus: Record<NodeId, Record<NodeId, PackedHLC>> = {};
  private broadcastIntervalMs: number;
  private broadcastInterval?: ReturnType<typeof setInterval>;

  constructor(
    networkConnector: NetworkConnector,
    options: BroadcastProtocolSyncProviderOptions = { intervalMs: 10000 }
  ) {
    this.networkConnector = networkConnector;
    this.broadcastIntervalMs = options.intervalMs;
  }

  public async init(sigil: SigilInterface, nodeId: string, documentId: string) {
    this.nodeId = nodeId;
    this.documentId = documentId;
    this.sigil = sigil;
    this.networkConnector.init({
      documentId: this.documentId,
      nodeId: this.nodeId,
      onMessage: this.onMessage,
    });
  }

  start = (messages: StampedMessage[]) => {
    // Initialise RAM. New messages + restored messages = the whole state of this provider.
    this.addGroupedSorted(messages);
    this.networkConnector.start();
    setInterval(this.broadcast, this.broadcastIntervalMs);
  };

  update = (messages: StampedMessage[]) => {
    // Save new messages to RAM. New messages + restored messages = the whole state of this node.
    this.addGroupedSorted(messages);
  };

  destroy = () => {
    this.networkConnector.stop();
    if (this.broadcastInterval) {
      clearInterval(this.broadcastInterval);
      this.broadcastInterval = undefined;
    }
  };

  private broadcast = () => {
    if (!this.nodeId || !this.sigil) {
      throw new Error("sigil or nodeId is not set");
    }

    const otherMessages = omit(this.messagesPerNode, [this.nodeId]) as Record<
      string,
      StampedMessage[]
    >;
    // Construct the question that we want to ask the room.
    // Remember that for a question, we can have an empty node / count in a HLC
    // if the node has just discovered a peer and has no starting point, eg {q: [ "timestamp:counter:nodeId:", "::nodeId" ]}
    const q = map(otherMessages, (messages, nodeId) => {
      if (messages.length === 0) {
        return `::${nodeId}`;
      }
      return last(messages)!.hlc;
    });

    // Look at the other peer's this.peerStatus[peer][otherPeer] and construct the answers on the fly.
    // Then remove the node from the question list, even if we didn't exhaust the question.
    // Just let the other nodes ask for them again. Keep it simple.
    // a: [ ["nodeId", "<lastHlc>", StampedMessage, StampedMessage, ...], ...] }
    const a: AMessage[] = [];
    forEach(this.peerStatus, (status, requesterNodeId) => {
      forEach(status, (latestPackedHLC, neededNodeId) => {
        // TODO if needed node is in the HLC, why do we group it by node?
        const { ts } = this.sigil!.unpack(latestPackedHLC);
        // No info for this node, return and continue
        if (!this.messagesPerNode[neededNodeId]) {
          return;
        }
        if (ts) {
          // If we have the latest node, answer the message with the latest HLC prefixed.
          const ourIndex = this.messagesPerNode[neededNodeId].findIndex(
            (stampedMessage) => stampedMessage.hlc === latestPackedHLC
          );
          if (ourIndex !== -1) {
            const candidate = this.messagesPerNode[neededNodeId].slice(
              ourIndex + 1,
              ourIndex + MAX_RESPONSE_ITEMS
            );
            if (candidate.length) {
              a.push([
                this.messagesPerNode[neededNodeId][ourIndex].hlc,
                ...candidate,
              ]);
            }
          }
        } else {
          const candidate = this.messagesPerNode[neededNodeId].slice(
            0,
            MAX_RESPONSE_ITEMS
          );
          if (candidate.length) {
            // If there is no latest node because the requester has no data, prefix it with ::nodeId
            a.push([`::${neededNodeId}`, ...candidate]);
          }
        }
        // We found a response; remove the question from the list of questions
        delete this.peerStatus[requesterNodeId][neededNodeId];
      });
    });

    const message: PeerMessage = {
      n: this.nodeId,
      q: q.length > 0 ? q : undefined,
      a: a.length > 0 ? a : undefined,
    };

    this.networkConnector.broadcast(message);
  };

  private onMessage = (message: unknown) => {
    const { n, q, a } = message as PeerMessage;
    if (n === this.nodeId) {
      // Ignore messages from ourselves
      return;
    }
    // Check if we discovered a new node
    if (!(n in this.messagesPerNode)) {
      this.messagesPerNode[n] = [];
    }
    q?.forEach((q) => {
      const { node } = this.unpackMessageHLC(q);
      // Check if we discovered a new node
      if (!(node in this.messagesPerNode)) {
        this.messagesPerNode[node] = [];
      }
      // The last message that a node "n" has for another node "node"
      // We will use this when we broadcast answers
      if (!this.peerStatus[n]) this.peerStatus[n] = {};
      this.peerStatus[n][node] = q;
    });
    a &&
      forEach(a, ([lastHlc, ...stampedMessages]) => {
        // Parse the hook point to understand the node id
        const { node: nodeId } = this.unpackMessageHLC(lastHlc);
        // Check if we discovered a new node
        if (!(nodeId in this.messagesPerNode)) {
          this.messagesPerNode[nodeId] = [];
        }
        // Find if we have an update for this node, by finding a message we already have in the last position.

        const lastMessage = last(this.messagesPerNode[nodeId]);
        let hookIndex: number | undefined = undefined;
        if (!lastMessage) {
          // If we don't have any message, find a zero marker
          if (lastHlc === `::${nodeId}`) {
            // console.log(
            //   "found a zero hook point in a message sequence (case 1)"
            // );
          }
          // todo should this be outside the if?
          hookIndex = 0;
        } else {
          if (lastHlc === lastMessage.hlc) {
            // console.log(
            //   "found a precise hook point at the very start of a message sequence (case 2)"
            // );
            hookIndex = 0;
          } else {
            stampedMessages.some((stampedMessage, index) => {
              if (
                // The last message *we* have appears in this sequence
                stampedMessage.hlc === lastMessage?.hlc &&
                // But it is not the last message of the sequence
                index !== stampedMessages.length - 1
              ) {
                // console.log(
                //   "found a hook point in the middle of a message sequence (case 3)",
                //   stampedMessage,
                //   "at index",
                //   index
                // );
                hookIndex = index + 1;
                return true;
              }
              return false;
            });
          }
        }
        if (hookIndex !== undefined) {
          // Get the messages to add
          const messagesToAdd = stampedMessages.slice(hookIndex);
          // Add them to our cache
          this.messagesPerNode[nodeId] = [
            ...this.messagesPerNode[nodeId],
            ...messagesToAdd,
          ];
          // We can also save them to call update at the end
          if (!this.sigil) {
            throw new Error("sigil is not defined");
          }
          this.sigil.update(messagesToAdd, this.name);
        }
      });
  };

  // TODO this can be a bit more performant / optimized.
  private addGroupedSorted = (messages: StampedMessage[]) => {
    if (!this.sigil) {
      throw new Error("sigil is not defined");
    }

    const groupedMessages = messages.reduce<Record<string, StampedMessage[]>>(
      (acc, message) => {
        const { hlc } = message;
        const { node } = this.sigil!.unpack(hlc); // TODO type
        acc[node] = [...(acc[node] || []), message];
        return acc;
      },
      {}
    );
    // Lexicographical order. Sort mutates the array.
    Object.keys(groupedMessages).forEach((node) => {
      groupedMessages[node].sort((a, b) => (a.hlc > b.hlc ? 1 : -1));
      const concatenatedGroup = [
        ...(this.messagesPerNode[node] ?? []),
        ...groupedMessages[node],
      ];
      concatenatedGroup.sort((a, b) => (a.hlc > b.hlc ? 1 : -1));
      // This is optimized for sorted arrays.
      this.messagesPerNode[node] = sortedUniqBy(concatenatedGroup, "hlc");
    });
  };

  private unpackMessageHLC = (hlc: string) => {
    if (!this.sigil) {
      throw new Error("sigil is not defined");
    }
    if (hlc.startsWith("::")) {
      return {
        node: hlc.substring(2),
        ts: -1,
        count: -1,
      };
    }
    return this.sigil.unpack(hlc);
  };
}
