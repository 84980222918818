import { Outlet } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import type { User } from "@supabase/supabase-js";

export function Layout({
  user,
  signOut,
}: {
  user: User | null | undefined;
  signOut: () => Promise<void>;
}) {
  return (
    <div className="app-container h-full">
      <Navbar className="header" user={user} signOut={signOut} />
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}
