import { Navigate } from "react-router-dom";
import { Login } from "./components/Login";
import type { User } from "@supabase/supabase-js";

export function Home({
  user,
  signIn,
}: {
  user: User | null | undefined;
  signIn: (email: string) => Promise<void>;
}) {
  return (
    <div>
      {user ? (
        <Navigate to="/store" replace />
      ) : (
        //   <a href={process.env.REACT_APP_BOOKMARKLET}>Add page bookmarklet</a>
        <Login
          title="Sign in / Sign up"
          text="Enter your email. You will receive a magic link that will log you into your account."
          onSubmit={signIn}
        />
      )}
    </div>
  );
}
